<template>
  <!-- 用户详情页面 -->
  <moe-page title="用户详情">
    <moe-describe-list title="用户信息" :col="3">
      <moe-describe-item label="昵称">{{ user.name }} </moe-describe-item>
      <moe-describe-item label="联系方式">{{ user.phone }} </moe-describe-item>
      <moe-describe-item label="性别">{{ $moe_format.getSex(user.sex) }}</moe-describe-item>
      <moe-describe-item label="生日">{{ user.birthday }}</moe-describe-item>
      <moe-describe-item label="宠物数量">{{ user.petsNum }}</moe-describe-item>
      <moe-describe-item label="关联设备数">{{ user.deviceNum }}</moe-describe-item>
      <moe-describe-item label="标签">
        <div class="df aic">
          <moe-tag class="mr-10" v-for="(item, index) in user.tagList" :key="index">{{ item }}</moe-tag>
        </div>
      </moe-describe-item>
    </moe-describe-list>

    <!-- 挂阁 -->
    <moe-describe-list title="宠物信息">
      <div class="df1 df fdc overflow-h" style="height: 300px;min-height: 300px;">
        <moe-table style="width: 100%" :data="user.userTerpets" :mode="false" :params="{ pageNum: 1 }" :number-show="false">
          <el-table-column prop="varieties" label="宠物种类" min-width="130">
            <template slot-scope="{ row }">{{ $moe_format.getPetType(row.species) }}</template>
          </el-table-column>
          <el-table-column prop="varieties" label="宠物品种" min-width="130" />
          <el-table-column prop="name" label="宠物名字" min-width="130" />
          <el-table-column prop="sex" label="性别" min-width="130">
            <template slot-scope="{ row }">{{ $moe_format.getPetSex(row.sex) }}</template>
          </el-table-column>
          <el-table-column prop="age" label="年龄" min-width="150" />
          <el-table-column prop="birthday" label="情感经历" min-width="120" >
            <template slot-scope="{ row }">{{ emotionO[row.emotionStage] || '无' }}</template>
          </el-table-column>
          <el-table-column prop="birthday" label="宠物生日" min-width="150" />
          <el-table-column prop="foodName" label="宠物粮" min-width="150" />
        </moe-table>
      </div>
    </moe-describe-list>

    <!-- 挂阁 -->
    <moe-describe-list title="宠物病例">
      <div class="df1 df fdc overflow-h" style="height: 300px;min-height: 300px;">
        <moe-table style="width: 100%" :data="user.petsCaseList" :mode="false" :params="{ pageNum: 1 }" :number-show="false">
          <el-table-column prop="petsName" label="宠物名字" min-width="150" />
          <el-table-column prop="title" label="病例名称" min-width="150" />
          <el-table-column prop="creationTime" label="创建时间" min-width="150" />
          <el-table-column prop="content" label="备注" min-width="150" />
          <el-table-column prop="birthday" label="图片" min-width="150" >
            <template slot-scope="{ row }">
              <moe-image :src="setPetsImage(row.caseImage).paths" width="50px" height="50px" />
              <p class="image-number" v-if="setPetsImage(row.caseImage).length>1">+{{setPetsImage(row.caseImage).length}}</p>
            </template>
          </el-table-column>
        </moe-table>
      </div>
    </moe-describe-list>

    <!-- 挂阁 -->
    <moe-describe-list title="设备信息">
      <div class="df1 df fdc overflow-h" style="height: 300px;min-height: 300px;">
        <moe-table style="width: 100%" :data="user.userDevices" :mode="false" :params="{ pageNum: 1 }" :number-show="false">
          <el-table-column prop="uid" label="设备ID" />
          <el-table-column prop="familyId" label="家庭ID" />
          <el-table-column prop="name" label="设备名称" min-width="130" >
            <template slot-scope="{ row }">{{ row.name || formatterType(row.type) }}</template>
          </el-table-column>
          <el-table-column prop="type" label="设备型号" min-width="130">
            <template slot-scope="{ row }">{{ formatterType(row.type) }}</template>
          </el-table-column>
        </moe-table>
      </div>
    </moe-describe-list>
  </moe-page>
</template>

<script>
export default {
  name: 'UserDetail',
  data() {
    return {
      user: {}, //用户信息,
      emotionO:{//情感经历
        1:'小屁孩',
        2:'情窦初开',
        3:'情场老手',
        4:'专业奶爸'
      },
      deviceModelList: [],
    };
  },
  mounted(){
    /** 查看所有设备型号 */
    this.$moe_api.EQUIPMENT_API.readAll().then((data) => {
      if (data.code === 200) {
        this.deviceModelList = data.result;
      }
    })
    /** 获取用户详情 */
    this.getuserDetail();
  },
  methods: {
    /**
     * 获取用户详情
     **/
    getuserDetail() {
      const userId = this.$route.query.id;
      // 调用获取用户详情API
      this.$moe_api.USER_API.userDetail({ userId }).then((data) => {
        if (data.code == 200) {
          let u = data.result;

          // 回显用户信息
          this.user = {
            name: u.name, //昵称
            phone: u.phone, //手机号
            sex: u.sex, //性别
            petsNum: u.petsNum, //宠物数
            deviceNum: u.deviceNum, //设备数
            userTerpets: u.userTerpets, //宠物信息
            petsCaseList: u.petsCaseList, //病例信息
            userDevices: u.userDevices, //设备信息
            tagList: u.tagList,
          };
        } else {
          this.$moe_msg.error(data.message);
        }
      });
    },

    /**
     * 处理病例图片
     */
    setPetsImage(imgs){
      let imgA=imgs.split(',');
      return{
        paths:imgA,
        length:imgA.length
      }

    },

    /**
     * 提交提醒事项信息
     **/
    userInfoSubmit() {
      // 表单校验
      this.$refs['userForm'].validate(() => {
        this.userLoad = true;
        this.$moe_api.PET_API.petuserAdd(this.user)
          .then((data) => {
            if (data.code == 200) {
              this.$moe_msg.success('添加成功');
              this.$router.go(-1);
            } else {
              this.$moe_msg.error(data.message);
            }
          })
          .finally(() => {
            this.userLoad = false;
          });
      });
    },

    formatterType(type) {
      let findItem = this.deviceModelList.find(({ id }) => id === type)
      if (findItem) {
        return findItem.name
      }
    },
  },
};
</script>

<style scoped>
  /* 图片数量 */
  .image-number{
    position: relative;
    float: right;
    left: -55px;
    top: 15px;
  }
</style>